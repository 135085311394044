<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>

        <e-col style="max-width: 33%">
              <tipo-bem-select
                  ref="tipoPai"
                  placeholder="Qualquer"
                  label="Tipo de bem:"
                  field-view="tl"
                  list-all=""
                  v-model="filters.tipo" />
            </e-col>

        <e-col style="max-width: 33%">
          <erp-s-field view="tipo" label="RGI">
            <erp-input v-model="filters.rgi" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 33%">
          <erp-s-field view="tipo" label="Cartório">
            <erp-input v-model="filters.cartorio" disabled="true"/>
          </erp-s-field>
        </e-col>

      </e-row>

      <e-row mr>

        <e-col style="max-width: 33%">
          <erp-s-field view="tipo" label="Placa">
            <erp-input v-model="filters.placa" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 33%">
          <erp-s-field view="tipo" label="Chassi">
            <erp-input v-model="filters.chassi" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 33%">
          <erp-s-field view="tipo" label="Renavam">
            <erp-input v-model="filters.renavan" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
          @click="$uloc.window.close($root.wid)" />
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light"
          flat no-caps />
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  ErpSelect
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import { relatorio } from "@/domain/relatorios/services"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"
import TipoBemSelect from "@/components/bem/components/include/TipoSelect"

let filters = {
  tipoRelatorio: 'relatorios.bens',
  codigo: null,
  tipo: null,
  rgi: null,
  cartorio: null,
  endereco: null,
  chassi: null,
  placa: null,
  renavan: null,
}

export default {
  name: 'RelatorioBensWindow',
  mixins: [mixinPrint],
  props: {
    router: { required: true },
    leilaoId: { required: false }
  },
  data() {
    let f = JSON.parse(JSON.stringify(filters))
    return {
      tipoBem: {
          loading: true,
          serverData: []
        },
      formato: 'html',
      filters: f,

    }
  },
  mounted() {
    this.loadTipos()
  },
  computed: {},
  methods: {
    loadTipos() {
      listTiposArquivo(200, 1) // TODO: NO LIMIT
        .then(response => {
          this.tipos = response.data.result ? response.data.result.map(item => {
            return {
              ...item,
              label: item.nome,
              value: item.id
            }
          }) : []
        })
        .catch((error) => {
          this.alertApiError(error)
        })
    },
    createPrint: createPrint,
    actionPrint(action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      this.loading = true
      relatorio('relatorios.bens', this.filters, formato)
        .then(response => {
          this.loading = false
          formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/BemListPrint'), 'analise', 'Relatorio de Bens', 'landscape')
        })
        .catch(error => {
          this.loading = false
          if (formato === 'html') {
            this.alertApiError(error)
          } else {
            error.data.text().then(text => {
              this.alertApiError({ data: JSON.parse(text) })
            })
          }
        })
    }
  },
  components: {
    ECol,
    ERow,
    WindowFooter,
    WindowContent,
    ErpSField,
    ErpInput,
    TipoBemSelect,
    // ComitenteSelect,
    // DateInput,
    // ErpSelect
  }
}
</script>
